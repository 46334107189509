<template>
  <div class="register">
    <div class="content">
      <div class="item">
        <h3 class="registerTip">
          {{ $t('registerTip') }}
        </h3>
      </div>
      <div class="item">
        <mt-field
            :placeholder="$t('phonePlaceholder')"
            v-model="form.phone"
            type="tel"
            :v-mask="'#### #### ###'"
            :state="phoneState"
        ></mt-field>
      </div>
      <div class="item">
        <mt-field
            :placeholder="$t('pinPlaceholder')"
            :v-model="form.password"
            type="number"
            :attr="{ maxlength: 4 }"
            :state="pinState"
        >
          <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
        </mt-field>
      </div>
      <div class="item">
        <mt-field
            :placeholder="$t('confirmPinPlaceholder')"
            v-model="form.comfirmPassword"
            type="number"
            :attr="{ maxlength: 4 }"
            :state="comfirmPinState"
        >
          <img v-if="!newVisible" class="visible" src="../../assets/notVisible.png" height="20px" @click="newVisible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="newVisible = false">
        </mt-field>
      </div>
      <div class="item">
        <read-view v-model="checked" :src="privacyUrl" :title="$t('serviceAgreement')" />
      </div>
      <div class="item">
        <mt-button size="large" @click="submit">{{
            $t("register")
          }}
        </mt-button>
      </div>
    </div>
    <get-code ref="getCode" :phone="form.phone" type="REGISTER" @submit="getCodeRes" />
  </div>
</template>

<script>
import {
  phoneRules,
  validateCodeRules, comfirmPasswordRules, passwordRules, pinRules, comfirmPinRules
} from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import { registeredUser } from '../../utils/api'
import getCode from '@/components/getCode.vue'
import readView from '@/components/readView.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'Register',
  components: {
    readView,
    getCode,
  },
  mixins: [mixin],
  props: ['value'],
  data() {
    return {
      checked: false,
      visible: false,
      newVisible: false,
    }
  },
  computed: {
    ...mapState(["record", "brand"]),
    privacyUrl() {
      if (this.brand.channelInfo) {
        return this.brand.channelInfo.privacyUrl
      }
      return ''
    },
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "registerPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
    comfirmPasswordState() {
      this.SET_RECORD_ITEM({
        key: "comfirmRegisterPassword",
        value: this.form.comfirmPassword,
      });
      return this.isCheck ? comfirmPasswordRules(this.form.comfirmPassword, this.form.password) : null;
    },
    pinState() {
      this.SET_RECORD_ITEM({
        key: "registerPassword",
        value: this.form.password,
      });
      return this.isCheck ? pinRules(this.form.password) : null;
    },
    comfirmPinState() {
      this.SET_RECORD_ITEM({
        key: "comfirmRegisterPassword",
        value: this.form.comfirmPassword,
      });
      return this.isCheck ? comfirmPinRules(this.form.comfirmPassword, this.form.password) : null;
    }
  },
  watch: {
    checked(val) {
      this.SET_CHECKED_SERVICE_AGREEMENT(val)
    }
  },
  created() {
    gtmPageView(this)
    this.checked = this.$store.state.checkedServiceAgreement || false;
    this.form.phone = this.record.registerPhone || "";
    this.form.password = this.record.registerPassword || "";
    this.form.password = this.record.comfirmRegisterPassword || "";
    this.form.validateCode = this.record.registerCode || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN", "SET_CHECKED_SERVICE_AGREEMENT"]),
    onLogin() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      this.$router.back(-1)
    },
    handlePassword(){
      this.form.password = "1111"
    },
    async submit() {
      if (!this.checked) {
        this.$toast(this.$t('readViewTips2'));
        return;
      }
      this.isCheck = true;
      var error = this.validation(['phone', 'password','comfirmPassword']);
      if (error) return this.$toast(error);
      // 开启框体
      this.$refs.getCode.popup(true)
    },
    async register(){
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('06_REGISTER', { content_name: '06_REGISTER' })
        }
        // Google Analytics  REGISTER
        if (localStorage['ga']) {
          window.gtag('event', 'REGISTER')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        phone: this.form.phone.replace(/\s/g, ""),
        pin: this.form.password,
        confirmPin: this.form.comfirmPassword,
        validateCode: this.form.validateCode,
        isValidateCode: this.form.isValidateCode,
        deviceId: "H5",
        device: "H5",
      }
      await this.$axios({
        method: "post",
        url: registeredUser,
        data: data,
      })
          .then(async (e) => {
            if (e.status.code == "000") {
              this.SET_ACCESS_TOKEN(e.body.accessToken);
              this.SET_RECORD_ITEM({
                key: "loginPhone",
                value: this.form.phone,
              });
              this.SET_RECORD_ITEM({
                key: "loginPassword",
                value: this.form.password,
              });
              await this.getUser();
              // 跳转 首页
              this.$router.push("/")
            } else if (e.status.code === '986') {
              this.SET_RECORD_ITEM({
                key: "loginPhone",
                value: this.form.phone,
              });
              this.$emit('input', 'login')
              this.$toast(e.status.msg)
            } else {
              this.$toast(e.status.msg)
            }
          })
          .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
      branch.logEvent('06_REGISTER', { metadata: data })
    },
    async getCodeRes(data){
      this.form.validateCode =  data.validateCode;
      this.form.isValidateCode = data.isValidateCode;
      // 关闭弹窗
      this.$refs.getCode.popup(false)
      // 注册
      await this.register();
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  position: relative;
  min-height: 100vh;
  background-image: url("../../assets/rigister_bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    padding-right: 5vw;
    padding-left: 5vw;
    .item {
      position: relative;
      margin-top: 30px;
      .mint-button{
        height:55px;
        border-radius: 50px;
        font-weight: 600;
        background: linear-gradient(#BB97FA, #855EF4);
        color: #FFFFFF;
        border:1px solid #FFFFFF;
      }
      .mint-cell {
        background-color: #f3effe;
        border-radius: 50px;
        /deep/ .mint-cell-wrapper {
          background: none;

        }
        /deep/ input {
          background-color: #f3effe;
        }
        /deep/ .mint-field-core {
          background-color: #f3effe;
          text-align: center;
          caret-color: #855ef4;
          font-size: 20px;
        }
        /deep/ input::placeholder{
          color: #999999;
          font-size: 16px;
        }
      }
      .confirm-pin-show::after{
        content: '';
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        background: url("../../assets/browse.png") no-repeat;
        background-size: 100% auto;
      }
      .confirm-pin-un-show::after{
        content: '';
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        background: url("../../assets/notVisible.png");
        background-size: 100% auto;
      }
      .registerTip {
        text-align: center;
        color: #1D1D1D;
      }
    }
  }
}
</style>